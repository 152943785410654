import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import styled from "@emotion/styled"
const ArticleWrapper = styled.div`
    max-width: 65rem;
    margin: 0 auto;
`
const ArticleTitle = styled.h1`
    font-size: 4rem;
`
const ArticleMetaData = styled.ul`
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
`
const ArticleMetaDataItem = styled.li`
    margin: 0 1em 0 0;
    padding: 0;
    text-transform: capitalize;
`
const ArticlePage = ({ pageContext, data }) => {
    const { tags } = pageContext
    const TagList = tags
    .map(tag => <ArticleMetaDataItem>{tag}</ArticleMetaDataItem>)
    const post = data.markdownRemark
    return (
        <Layout>
            <ArticleWrapper>
                <ArticleTitle>{post.frontmatter.title}</ArticleTitle>
                <ArticleMetaData>
                    {TagList}
                    <ArticleMetaDataItem>{post.frontmatter.dateUpdated}</ArticleMetaDataItem>
                </ArticleMetaData>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </ArticleWrapper>
        </Layout>
    )
}
ArticlePage.propTypes = {
    pageContext: PropTypes.shape({
        tags: PropTypes.array,
    }),
}
export default ArticlePage
export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                tags
                dateUpdated(formatString: "MMMM DD, YYYY")
            }
        }
    }
`